import * as React from 'react';
import Helmet from 'react-helmet';
import { Layout, Header, PageTitle, PageSubTitle, Main, Footer, mq } from '../components/Layout';
import { Container } from '../components/Container';
import { Section, SectionSplitter, LeftSection, RightSection, Title } from '../components/Section';
import { Ul, Li } from '../components/UnorderedList';
import heroImage from '../images/hero.jpg';

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <title>Home</title>
      </Helmet>

      <Layout>
        <Header />

        <Main>
          <Section css={{ paddingBottom: '48px' }}>
            <Container
              css={{
                padding: '12px',
                [mq(1)]: {
                  backgroundImage: `url(${heroImage})`,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'top right',
                },
              }}
            >
              <div
                css={{
                  fontSize: '4px',
                  textAlign: 'center',
                  [mq(0)]: { fontSize: '6px' },
                  [mq(1)]: { textAlign: 'left', maxWidth: '50%' },
                }}
              >
                <PageTitle>
                  Oog op participatie
                  <br />
                  <span css={{ fontFamily: 'Arial, Helvetica, sans-serif', color: '#777' }}>
                    &
                  </span>{' '}
                  duurzaamheid
                </PageTitle>
                <PageSubTitle css={{ paddingTop: '12px' }}>
                  Bekijk{' '}
                  <a href="#onze-visie" css={{ fontWeight: 600 }}>
                    de visie
                  </a>{' '}
                  van Stichting MijnGilde
                </PageSubTitle>
              </div>
            </Container>
          </Section>

          <Container>
            <SectionSplitter>
              <LeftSection>
                <Title id="onze-visie">Onze visie</Title>
                <main>
                  <p>
                    In Nederland zijn er steeds meer mensen die moeite hebben om in de samenleving
                    te participeren. Dit kan zijn op het gebied van werk, maar ook op het gebied van
                    het leggen van contacten met anderen. Daarnaast zijn er groepen die tijdelijk
                    aan de kant staan door een langdurige ziekte of aandoening.
                  </p>

                  <p>
                    Door bezuinigingen zie je dat gemeenten zich ook steeds meer terugtrekken uit
                    allerlei sociale initiatieven en dagbestedingsprojecten.
                  </p>

                  <p>
                    Op scholen zie je dat er steeds minder tijd gegeven wordt aan creatieve vakken
                    en textiele werkvormen.
                  </p>

                  <p>
                    Creativiteit is wel heel belangrijk voor onze samenleving en creativiteit is ook
                    heel belangrijk voor de mens zelf. Breien, haken en borduren zijn activiteiten
                    die ontspannen en mensen tot rust brengen. Dat is heel belangrijk in onze
                    hectische maatschappij. Het is dan ook een middel bij het oplossen van
                    burn-outklachten. Creativiteit kan mensen ook met elkaar in contact brengen.
                    Samen bezig zijn is leuker dan alleen thuis zitten. Het maken en creëren van
                    nieuwe producten laat ook zien wat je kunt. Het maakt je trots dat jij dat hebt
                    gemaakt.
                  </p>

                  <p>
                    Het stimuleren van creativiteit en het in stand houden van in ons geval textiele
                    ambachten is in onze ogen dan ook van groot belang.
                  </p>

                  <p>
                    Daarnaast is het hergebruik van materialen erg belangrijk voor onze stichting.
                    De vervuiling van de textiel industrie en de toenemende afvalberg is een grote
                    belasting voor ons leefmilieu. De leefomgeving waar wij zelf in wonen.
                  </p>

                  <p>
                    Dit zijn voor ons zwaarwegende redenen geweest om eind vorig jaar onze Stichting
                    MijnGilde op te richten.
                  </p>
                </main>
              </LeftSection>

              <RightSection>
                <Title>Doelstellingen</Title>
                <Ul>
                  <Li>
                    Het bevorderen van de participatie van mensen die door werkloosheid, ziekte of
                    aandoening op dit moment niet aan het werk kunnen of kunnen participeren
                  </Li>
                  <Li>
                    Het bevorderen en in stand houden van ambachtelijke technieken zoals naaien,
                    breien, haken, borduren, naaldvilten en schilderen op textiel.
                  </Li>
                  <Li>
                    Het leveren van een bijdrage aan de vermindering van textielafval, door bij het
                    vervaardigen van nieuwe kleding en woonaccessoires voornamelijk gebruik te maken
                    van oude en/of gekregen materialen
                  </Li>
                </Ul>
              </RightSection>
            </SectionSplitter>
          </Container>
        </Main>

        <Footer />
      </Layout>
    </>
  );
};

export default IndexPage;
