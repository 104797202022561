import * as React from 'react';

export const Ul = ({ type, ...props }) => (
  <ul
    css={{
      margin: 0,
      padding: 0,
      position: 'relative',
      listStyle: 'none',

      '> li': {
        paddingLeft: 28,
        paddingBottom: 12,
      },
      '> li:before': {
        content: '"★"',
        color: '#f59492',
        left: 0,
        position: 'absolute',
      },
    }}
    {...props}
  />
);
export const Li = (props) => <li {...props} />;
